// @flow strict
import * as React from 'react'
import _ from 'lodash'
import {
  Alert,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  ButtonGroup,
  Row,
  Col,
} from 'reactstrap'
import type {
  UpdateInvitationInput,
  UpdateGuestInput
} from '../mutations/__generated__/UpdateInvitationMutation.graphql'
import { createFragmentContainer, graphql } from 'react-relay'
import type { Guest, Invitation } from '../data/Invitation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlusCircle,
  faMinusCircle,
  faCheckCircle,
  faTimesCircle,
  faFish,
  faCarrot,
  faChild,
  faCow,
} from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'
import DeleteInvitation from '../mutations/DeleteInvitation'

type Props = {
  invitation: Invitation,
  onSubmit: UpdateInvitationInput => void,
}

type GuestTouched = {
  firstName: boolean,
  lastName: boolean,
}

type InvitationTouched = {
  nameOnEnvelope: boolean,
  guests: Array<GuestTouched>,
  numAdditionalGuests: boolean,
}

type GuestErrors = {
  firstName: ?string,
  lastName: ?string,
}

type InvitationErrors = {
  nameOnEnvelope: ?string,
  guests: Array<GuestErrors>,
  numAdditionalGuests: ?string,
}

type State = {
  invitation: UpdateInvitationInput,
  touched: InvitationTouched,
  errors: InvitationErrors,
}

class UpdateInvitationForm extends React.Component<Props, State> {
  _handleSubmit: SyntheticEvent<HTMLFormElement> => void
  _handleAddGuest: number => void => void
  _handleRemoveGuest: number => void => void
  _handleChange: string => SyntheticInputEvent<HTMLInputElement> => void
  _handleGuestChange: (number, string) => SyntheticInputEvent<HTMLInputElement> => void
  isValid: void => boolean
  _validate: boolean => void
  _renderInvitation: void => React.Node

  _mealLabelMap = {
    'BEEF': 'Beef',
    'SALMON': 'Salmon',
    'VEGETARIAN': 'Vegetarian',
    'KIDS_MEAL': 'Kids Meal',
  }

  _mealIcon = {
    'BEEF': faCow,
    'SALMON': faFish,
    'VEGETARIAN': faCarrot,
    'KIDS_MEAL': faChild,
  }

  constructor(props: Props) {
    super(props)
    const { invitation } = props
    if (invitation === null) {
      this.state = {
        invitation: null,
      }
    } else {
      this.state = {
        invitation: {
          nameOnEnvelope: invitation.nameOnEnvelope,
          guests: _.map(invitation.guests, this._mapGuestToUpdateGuestInput),
          numAdditionalGuests: invitation.totalAllowedGuests - invitation.guests.length,
        },
        touched: {
          nameOnEnvelope: false,
          guests: _.map(invitation.guests, guest => ({
            firstName: false,
            lastName: false,
          })),
          numAdditionalGuests: false,
        },
        errors: {
          nameOnEnvelope: null,
          guests: _.map(invitation.guests, guest => ({
            firstName: null,
            lastName: null,
          })),
          numAdditionalGuests: null,
        },
      }
    }
    this._handleSubmit = this._handleSubmit.bind(this)
    this._handleAddGuest = this._handleAddGuest.bind(this)
    this._handleRemoveGuest = this._handleRemoveGuest.bind(this)
    this._handleChange = this._handleChange.bind(this)
    this._handleGuestChange = this._handleGuestChange.bind(this)
    this._validate = this._validate.bind(this)
    this._renderInvitation = this._renderInvitation.bind(this)
  }

  _handleSubmit(event: SyntheticEvent<HTMLFormElement>): void {
    const { onSubmit } = this.props
    event.preventDefault()
    this._validate(true)
    if (!this.isValid()) {
      // Not valid. Let this re-render.
      return
    }
    const { invitation } = this.state
    invitation.numAdditionalGuests = parseInt(invitation.numAdditionalGuests)
    if (isNaN(invitation.numAdditionalGuests)) {
      invitation.numAdditionalGuests = 0
    }
    const guestsInput =
      _.map(invitation.guests, guest => _.omit(guest, ['responseDate']))
    const input = Object.assign({}, invitation, { guests: guestsInput })
    onSubmit(input)
  }

  _handleAddGuest(index: number) {
    return () => {
      const { invitation, touched, errors } = this.state
      invitation.guests.push({
        firstName: '',
        lastName: '',
        responseDate: null,
        attending: null,
        meal: null,
        anonymousGuest: false,
      })
      touched.guests.push({
        firstName: false,
        lastName: false,
      })
      errors.guests.push({
        firstName: null,
        lastName: null,
      })
      this.setState({
        invitation,
        touched,
        errors,
      })
    }
  }

  _handleRemoveGuest(index: number) {
    return () => {
      const { invitation, touched, errors } = this.state
      if (invitation.guests.length === 1) {
        invitation.guests[0].firstName = ''
        invitation.guests[0].lastName = ''
        touched.guests[0].firstName = false
        touched.guests[0].lastName = false
        errors.guests[0].firstName = null
        errors.guests[0].lastName = null
      } else {
        invitation.guests = _.filter(invitation.guests, (guest, i) => i !== index)
        touched.guests = _.filter(touched.guests, (guest, i) => i !== index)
        errors.guests = _.filter(errors.guests, (guest, i) => i !== index)
        this.setState({
          invitation,
          touched,
          errors,
        })
      }
    }
  }

  _handleChange(name: string): SyntheticInputEvent<HTMLInputElement> => void {
    return (event: SyntheticInputEvent<HTMLInputElement>) => {
      const { invitation, touched } = this.state
      invitation[name] = event.target.value
      touched[name] = true
      this.setState({
        invitation,
        touched,
      })
      this._validate(false)
    }
  }

  _handleGuestChange(index: number, name: string):
    SyntheticInputEvent<HTMLInputElement> => void {
    return (event: SyntheticInputEvent<HTMLInputElement>) => {
      const { invitation, touched } = this.state
      invitation.guests[index][name] = event.target.value
      touched.guests[index][name] = true
      this.setState({
        invitation,
        touched,
      })
      this._validate(false)
    }
  }

  isValid(): boolean {
    const { errors } = this.state
    let result = errors.nameOnEnvelope === null &&
      errors.numAdditionalGuests === null
    _.forEach(
      errors.guests,
      guest => result = result &&
        guest.firstName === null &&
        guest.lastName === null
    )
    return result
  }

  _validate(submit: boolean): void {
    const { invitation, touched, errors } = this.state
    if (submit || touched.nameOnEnvelope) {
      errors.nameOnEnvelope
        = invitation.nameOnEnvelope.trim() === '' ? 'Required' : null
    }
    _.forEach(
      invitation.guests,
      (guest, i) => {
        if (submit || touched.guests[i].firstName) {
          errors.guests[i].firstName
            = guest.firstName.trim() === '' ? 'Required' : null
        }
        if (submit || touched.guests[i].lastName) {
          errors.guests[i].lastName
            = guest.lastName.trim() === '' ? 'Required' : null
        }
      }
    )

    this.setState({
      errors: errors
    })
  }

  _renderInvitation(): React.Node {
    const { invitation, errors } = this.state
    const isValid = this.isValid()
    const guests = _.map(
      invitation.guests,
      (guest, i) => (
        <React.Fragment key={'guest-' + i}>
          <Row form>
            <Col md={5}>
              <FormGroup>
                <Input
                  name={'firstName-' + i}
                  placeholder="First Name"
                  onChange={this._handleGuestChange(i, 'firstName')}
                  value={guest.firstName}
                  invalid={errors.guests[i].firstName !== null}
                />
                {errors.guests[i].firstName &&
                  <FormFeedback>{errors.guests[i].firstName}</FormFeedback>
                }
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  field={'lastName-' + i}
                  placeholder="Last Name"
                  onChange={this._handleGuestChange(i, 'lastName')}
                  value={guest.lastName}
                  invalid={errors.guests[i].lastName !== null}
                />
                {errors.guests[i].lastName &&
                  <FormFeedback>{errors.guests[i].lastName}</FormFeedback>
                }
              </FormGroup>
            </Col>
            <Col md={1}>
              <ButtonGroup size="sm">
                <Button color="link" onClick={this._handleAddGuest(i)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
                <Button color="link" onClick={this._handleRemoveGuest(i)}>
                  <FontAwesomeIcon icon={faMinusCircle} />
                </Button>
              </ButtonGroup>
            </Col>
            <Col md={1} className="guest-selection">
              {guest.attending === null ?
                '' :
                (guest.attending === true ?
                  <FontAwesomeIcon icon={faCheckCircle} /> :
                  <FontAwesomeIcon icon={faTimesCircle} />)
              }
              {guest.attending === true &&
                <React.Fragment>
                  {' '}
                  <FontAwesomeIcon icon={this._mealIcon[guest.meal]} />
                </React.Fragment>
              }
              {guest.anonymousGuest === true &&
                <React.Fragment>
                  {' '}+1
                </React.Fragment>
              }
            </Col>
          </Row>
        </React.Fragment>
      )
    )
    return (
      <Form onSubmit={this._handleSubmit}>
        <FormGroup>
          <Label for="name-on-envelope">Name on Envelope</Label>
          <Input
            id="name-on-envelope"
            name="nameOnEnvelope"
            onChange={this._handleChange('nameOnEnvelope')}
            value={invitation.nameOnEnvelope}
            invalid={errors.nameOnEnvelope !== null}
          />
          {errors.nameOnEnvelope !== null &&
            <FormFeedback>{errors.nameOnEnvelope}</FormFeedback>
          }
        </FormGroup>
        <FormGroup>
          <Label>Guests</Label>
          {guests}
        </FormGroup>
        <FormGroup>
          <Label for="num-additional-guests">Number of Additional Guests</Label>
          <Input
            id="num-additional-guests"
            name="numAdditionalGuests"
            type="number"
            min="0"
            max="10"
            onChange={this._handleChange('numAdditionalGuests')}
            value={invitation.numAdditionalGuests}
          />
        </FormGroup>
        <Row>
          <Col md={9}>
            <Button type="submit" color="primary" disabled={!isValid}>
              Update
            </Button>{' '}
            <a
              className="btn btn-secondary"
              href={'https://tsueido.info/rsvp/' + this.props.invitation.code}
              rel="noopener noreferrer"
              target="_blank">
              Make Reservation
            </a>{' '}
            <Button tag={Link} to="/">Cancel</Button>{' '}
          </Col>
          <Col md={3} className="text-right">
            <Button onClick={this._deleteItem.bind(this)} color="danger">
              Delete
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  _deleteItem() {
    const { invitation, onDelete } = this.props
    DeleteInvitation.commit(invitation.code, (response, errors) => {
      if (errors) {
        console.log(errors)
      } else {
        onDelete()
      }
    })
  }

  render() {
    const { invitation } = this.state
    if (invitation === null) {
      return (
        <Alert color="warning" className="mt-4">
          <h4 className="alert-heading">Warning!</h4>
          <p>
            Invitation not found.
          </p>
        </Alert>
      )
    } else {
      return this._renderInvitation()
    }
  }

  _mapGuestToUpdateGuestInput(guest: Guest): UpdateGuestInput {
    return _.pick(
      guest,
      ['firstName', 'lastName', 'attending', 'meal', 'responseDate'],
    )
  }
}

export default createFragmentContainer(UpdateInvitationForm,
  graphql`
    fragment UpdateInvitationForm_invitation on Invitation {
      code
      nameOnEnvelope
      guests {
        firstName
        lastName
        attending
        meal
        responseDate
        anonymousGuest
      }
      totalAllowedGuests
    }
  `
)
