// @flow strict
import { graphql, commitMutation } from 'react-relay'
import type { Environment } from 'relay-runtime'

const mutation = graphql`
  mutation UpdateInvitationMutation($code: ID!, $invitation: UpdateInvitationInput!) {
    updateInvitation(code: $code, invitation: $invitation) {
      code
    }
  }
`

export type UpdateGuestInput = {
  firstName: string,
  lastName: string,
  attending: boolean,
  meal: string,
}

export type UpdateInvitationInput = {
  nameOnEnvelope: string,
  guests: Array<UpdateGuestInput>,
  numAdditionalGuests: number,
}

export function updateInvitation(
  environment: Environment,
  code: string,
  invitation: UpdateInvitationInput,
  onCompleted?: ?(response: ?Object, errors: ?Array<Error>) => void,
  onError?: ?(error: Error) => void,
) {
  commitMutation(
    environment,
    {
      mutation,
      variables: {
        code: code,
        invitation: invitation,
      },
      onCompleted,
      onError,
    },
  )
}
