/**
 * @flow
 * @relayHash 2d2fae9429b3966d80ec09521e6d7dc4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Meal = "BEEF" | "KIDS_MEAL" | "SALMON" | "VEGETARIAN" | "%future added value";
export type UpdateInvitationInput = {
  nameOnEnvelope: string,
  guests: $ReadOnlyArray<UpdateGuestInput>,
  numAdditionalGuests: number,
};
export type UpdateGuestInput = {
  firstName: string,
  lastName: string,
  attending?: ?boolean,
  meal?: ?Meal,
};
export type UpdateInvitationMutationVariables = {|
  code: string,
  invitation: UpdateInvitationInput,
|};
export type UpdateInvitationMutationResponse = {|
  +updateInvitation: ?{|
    +code: string
  |}
|};
export type UpdateInvitationMutation = {|
  variables: UpdateInvitationMutationVariables,
  response: UpdateInvitationMutationResponse,
|};
*/


/*
mutation UpdateInvitationMutation(
  $code: ID!
  $invitation: UpdateInvitationInput!
) {
  updateInvitation(code: $code, invitation: $invitation) {
    code
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "code",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "invitation",
    "type": "UpdateInvitationInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateInvitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code",
        "type": "ID!"
      },
      {
        "kind": "Variable",
        "name": "invitation",
        "variableName": "invitation",
        "type": "UpdateInvitationInput!"
      }
    ],
    "concreteType": "Invitation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "code",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateInvitationMutation",
  "id": null,
  "text": "mutation UpdateInvitationMutation(\n  $code: ID!\n  $invitation: UpdateInvitationInput!\n) {\n  updateInvitation(code: $code, invitation: $invitation) {\n    code\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateInvitationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateInvitationMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4de28c86ece341aab70ae2247d1282d';
module.exports = node;
