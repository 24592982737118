/**
 * @flow
 * @relayHash 2536a0de715ec6f777056cc23fe0a338
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteInvitationMutationVariables = {|
  code: string
|};
export type DeleteInvitationMutationResponse = {|
  +deleteInvitation: ?{|
    +code: string
  |}
|};
export type DeleteInvitationMutation = {|
  variables: DeleteInvitationMutationVariables,
  response: DeleteInvitationMutationResponse,
|};
*/


/*
mutation DeleteInvitationMutation(
  $code: ID!
) {
  deleteInvitation(code: $code) {
    code
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "code",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteInvitation",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code",
        "type": "ID!"
      }
    ],
    "concreteType": "Invitation",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "code",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DeleteInvitationMutation",
  "id": null,
  "text": "mutation DeleteInvitationMutation(\n  $code: ID!\n) {\n  deleteInvitation(code: $code) {\n    code\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeleteInvitationMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DeleteInvitationMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43a8735929e2abca7d0cd25580baabbb';
module.exports = node;
