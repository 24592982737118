// @flow strict
import * as React from 'react'
import { Button } from 'reactstrap'
import XLSX from 'xlsx'
import { createFragmentContainer, graphql } from 'react-relay'
import type { Invitation } from '../data/Invitation'
import _ from 'lodash'

type Props = {
  invitations: Array<Invitation>,
}

class ExportToExcelButton extends React.Component<Props> {
  _export(): void {
    const data = _.flatMap(
      this.props.invitations,
      invitation => {
        return _.map(
          invitation.guests,
          guest => (
            [
              invitation.nameOnEnvelope,
              invitation.code,
              guest.firstName,
              guest.lastName,
              guest.attending === null ? '' : guest.attending,
              guest.attending ? guest.meal : '',
              guest.dietaryRestrictions === null ? '' : guest.dietaryRestrictions,
            ]
          )
        )
      }
    )

    data.unshift(
      [
        'Name on Envelope',
        'Code',
        'First Name',
        'Last Name',
        'Attending',
        'Meal Selection',
        'Dietary Restrictions',
      ],
    )

    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.aoa_to_sheet(data)
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Guests')
    XLSX.writeFile(workbook, 'tsueido.xlsx')
  }

  render(): React.Node {
    return <Button onClick={this._export.bind(this)}>Export to Excel</Button>
  }
}

export default createFragmentContainer(
  ExportToExcelButton,
  graphql`
    fragment ExportToExcelButton_invitations on Invitation @relay(plural: true) {
      nameOnEnvelope,
      code,
      guests {
        firstName,
        lastName,
        attending,
        meal,
        dietaryRestrictions,
      }
    }
  `
)
