// @flow strict
import 'babel-polyfill'
import { Environment, RecordSource, Store } from 'relay-runtime'
import 'regenerator-runtime/runtime'
import {
  RelayNetworkLayer,
  authMiddleware,
  urlMiddleware,
} from 'react-relay-network-modern'
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { cognitoPoolData } from './config'
import { promisify } from 'es6-promisify'

const userPool = new CognitoUserPool(cognitoPoolData)

const store = new Store(new RecordSource())
const network = new RelayNetworkLayer(
  [
    urlMiddleware({
      url: req => Promise.resolve(
        'https://iv583iqwp8.execute-api.us-east-1.amazonaws.com/dev/admin/graphql/'
      ),
    }),
    authMiddleware({
      token: req => getIdToken(),
      tokenRefreshPromise: req => refreshToken(),
    }),
  ]
)

const environment = new Environment({
  network,
  store,
})

function refreshToken(): Promise<string> {
  const user = getCurrentUser()
  const refreshSessionImpl = promisify(user.refreshSession.bind(user))
  return getSession()
    .then(session => {
      const refreshToken = session.getRefreshToken()
      return refreshSessionImpl(refreshToken)
    })
    .then(session => {
      return session.getIdToken().getJwtToken()
    })
}

export function getCurrentUser(): any {
  return userPool.getCurrentUser()
}

export function getSession(): Promise<any> {
  const user = getCurrentUser()
  if (user !== null) {
    const getSessionImpl = promisify(user.getSession.bind(user))
    return getSessionImpl()
  } else {
    return new Promise((resolve, reject) => resolve(null))
  }
}

export function getIdToken(): Promise<string> {
  return getSession().then(session => {
    if (session !== null) {
      return session.getIdToken().getJwtToken()
    } else {
      return null
    }
  })
}

export default environment
