// @flow strict
import { graphql, commitMutation } from 'react-relay'
import type { Environment } from 'relay-runtime'

const mutation = graphql`
  mutation CreateInvitationMutation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      code
    }
  }
`

export type CreateGuestInput = {
  firstName: string,
  lastName: string,
  dietaryRestrictions: string,
}

export type CreateInvitationInput = {
  nameOnEnvelope: string,
  guests: Array<CreateGuestInput>,
  numAdditionalGuests: number,
}

export function createInvitation(
  environment: Environment,
  invitation: CreateInvitationInput,
  onCompleted?: ?(response: ?Object, errors: ?Array<Error>) => void,
  onError?: ?(error: Error) => void,
) {
  commitMutation(
    environment,
    {
      mutation,
      variables: {
        input: invitation
      },
      onCompleted,
      onError,
    },
  )
}
