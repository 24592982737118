// @flow strict
import * as React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
} from 'reactstrap'
import { Switch, Route } from 'react-router-dom'
import Invitations from './components/Invitations'
import CreateInvitation from './components/CreateInvitation'
import UpdateInvitation from './components/UpdateInvitation'
import Login from './components/Login'
import ChangePassword from './components/ChangePassword'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDog } from '@fortawesome/pro-solid-svg-icons'
import { getCurrentUser } from './environment'

type State = {
  userAttributes: any,
  passwordChangeUser: any,
  needsPasswordChange: boolean,
}

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)

    this.state = {
      userAttributes: null,
      passwordChangeUser: null,
      needsPasswordChange: false,
    }
  }

  _renderAuthenticated(): React.Node {
    return (
      <Switch>
        <Route exact path="/" component={Invitations} />
        <Route path="/create" component={CreateInvitation} />
        <Route path="/invitation/:code" component={UpdateInvitation} />
      </Switch>
    )
  }

  _renderUnauthenticated(): React.Node {
    if (this.state.needsPasswordChange) {
      return (
        <ChangePassword
          onLogin={this._handleLogin.bind(this)}
          userAttributes={this.state.userAttributes}
          user={this.state.passwordChangeUser}
          />
      )
    } else {
      return (
        <Login
          onLogin={this._handleLogin.bind(this)}
          onNewPasswordRequired={this._handleNewPassword.bind(this)}
          />
      )
    }
  }

  _handleLogin(response: any): void {
    this.setState({
      passwordChangeUser: null,
      needsPasswordChange: false,
    })
  }

  _handleNewPassword(
    user: any,
    userAttributes: any
  ): void {
    this.setState({
      passwordChangeUser: user,
      needsPasswordChange: true,
    })
  }

  _handleSignout(): void {
    const user = getCurrentUser()
    if (user !== null) {
      user.signOut()
    }
    this.setState({
      passwordChangeUser: null,
    })
  }

  render(): React.Node {
    const user = getCurrentUser()

    const content =
      user === null ?
      this._renderUnauthenticated() :
      this._renderAuthenticated()
    return (
      <div className="App">
        <Navbar color="light" light expand="md" className="mb-5">
          <NavbarBrand href="/">
            <FontAwesomeIcon icon={faDog} /> Evie the Dog
          </NavbarBrand>
          {user !== null &&
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {user.username}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={this._handleSignout.bind(this)}>
                    Sign out
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          }
        </Navbar>
        {content}
      </div>
    )
  }
}

export default App
