// @flow strict
import { graphql, commitMutation } from 'react-relay'
import environment from '../environment'
import _ from 'lodash'

const mutation = graphql`
  mutation DeleteInvitationMutation($code: ID!) {
    deleteInvitation(code: $code) {
      code,
    }
  }
`

const sharedUpdater = (store, code) => {
  const root = store.getRoot()
  const invitations = _.remove(
    root.getLinkedRecords('invitations'),
    i => i.getValue('code') !== code
  )
  root.setLinkedRecords(invitations, 'invitations')
}

function commit(
  code: string,
  onCompleted?: Function,
) {
  return commitMutation(
    environment,
    {
      mutation,
      variables: {
        code,
      },
      onCompleted,
      updater: store => {
        const payload = store.getRootField('deleteInvitation')
        sharedUpdater(store, payload.getValue('code'))
      },
      optimisticUpdater: store => sharedUpdater(store, code),
    },
  )
}

export default { commit }
