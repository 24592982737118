// @flow strict
import * as React from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Input,
  Row,
} from 'reactstrap'
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js'
import { cognitoPoolData } from '../config'

type Props = {
  onLogin: void => void,
  onNewPasswordRequired: (string, any) => void,
}

type State = {
  values: {
    username: string,
    password: string,
  },
  touched: {
    username: boolean,
    password: boolean,
  },
  errors: {
    username: ?string,
    password: ?string,
  },
}

class Login extends React.Component<Props, State> {
  _handleChange: SyntheticInputEvent<HTMLInputElement> => void
  _validate: boolean => void
  _isValid: void => boolean
  _handleSubmit: SyntheticEvent<HTMLFormElement> => void

  constructor(props: Props) {
    super(props)
    this.state = {
      values: {
        username: '',
        password: '',
      },
      touched: {
        username: false,
        password: false,
      },
      errors: {
        username: null,
        password: null,
        login: null,
      },
    }
    this._handleChange = this._handleChange.bind(this)
    this._validate = this._validate.bind(this)
    this._isValid = this._isValid.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  _handleChange(event: SyntheticInputEvent<HTMLInputElement>): void {
    const { values, touched } = this.state
    const name = event.target.name
    values[name] = event.target.value
    touched[name] = true
    this.setState({
      values,
      touched,
    })
    this._validate(false)
  }

  _validate(submit: boolean): void {
    const { values, touched, errors } = this.state
    if (submit || touched.username) {
      errors.username = values.username.trim() === '' ? 'Required' : null
    }
    if (submit || touched.password) {
      errors.password = values.password.trim() === '' ? 'Required' : null
    }
    this.setState({
      errors,
    })
  }

  _isValid(): boolean {
    const { errors } = this.state
    return errors.username === null && errors.password === null
  }

  _handleSubmit(event: SyntheticEvent<HTMLFormElement>): void {
    event.preventDefault()

    const { values, errors } = this.state
    errors.login = null
    this._validate(true)
    if (!this._isValid()) {
      return
    }

    const { onLogin, onNewPasswordRequired } = this.props
    const userPool = new CognitoUserPool(cognitoPoolData)
    const user = new CognitoUser({
      Username: values.username,
      Pool: userPool,
    })
    user.setAuthenticationFlowType('USER_PASSWORD_AUTH')
    const authenticationData = {
      Username: values.username,
      Password: values.password,
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    user.authenticateUser(
      authenticationDetails,
      {
        onSuccess: result => {
          onLogin(result)
        },
        onFailure: result => {
          errors.login = result.message
          this.setState({
            errors
          })
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified
          onNewPasswordRequired(user, userAttributes)
        },
      },
    )
  }

  render(): React.Node {
    const { errors } = this.state
    const isValid = this._isValid()
    return (
      <Container>
        <Row>
          <Col md={6}>
            <h1>Login</h1>
            {errors.login !== null &&
              <Alert color="danger">
                {errors.login}
              </Alert>
            }
            <Form onSubmit={this._handleSubmit}>
              <FormGroup>
                <Label for="login-username" hidden>Username</Label>
                <Input
                  name="username"
                  id="login-username"
                  placeholder="Username"
                  onChange={this._handleChange}
                  invalid={errors.username !== null}
                />
                <FormFeedback>{errors.username}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="login-password" hidden>Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="login-password"
                  placeholder="Password"
                  onChange={this._handleChange}
                  invalid={errors.password !== null}
                />
                <FormFeedback>{errors.password}</FormFeedback>
              </FormGroup>
              <Button type="submit" color="primary" disabled={!isValid}>
                Login
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Login
