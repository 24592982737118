/**
 * @flow
 * @relayHash 20c417a0090eebfcf731e4efaeafcb3d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UpdateInvitationForm_invitation$ref = any;
export type UpdateInvitationQueryVariables = {|
  code: string
|};
export type UpdateInvitationQueryResponse = {|
  +invitation: ?{|
    +$fragmentRefs: UpdateInvitationForm_invitation$ref
  |}
|};
export type UpdateInvitationQuery = {|
  variables: UpdateInvitationQueryVariables,
  response: UpdateInvitationQueryResponse,
|};
*/


/*
query UpdateInvitationQuery(
  $code: ID!
) {
  invitation(code: $code) {
    ...UpdateInvitationForm_invitation
  }
}

fragment UpdateInvitationForm_invitation on Invitation {
  code
  nameOnEnvelope
  guests {
    firstName
    lastName
    attending
    meal
    responseDate
    anonymousGuest
  }
  totalAllowedGuests
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "code",
    "type": "ID!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code",
    "type": "ID!"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "UpdateInvitationQuery",
  "id": null,
  "text": "query UpdateInvitationQuery(\n  $code: ID!\n) {\n  invitation(code: $code) {\n    ...UpdateInvitationForm_invitation\n  }\n}\n\nfragment UpdateInvitationForm_invitation on Invitation {\n  code\n  nameOnEnvelope\n  guests {\n    firstName\n    lastName\n    attending\n    meal\n    responseDate\n    anonymousGuest\n  }\n  totalAllowedGuests\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateInvitationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invitation",
        "storageKey": null,
        "args": v1,
        "concreteType": "Invitation",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UpdateInvitationForm_invitation",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateInvitationQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invitation",
        "storageKey": null,
        "args": v1,
        "concreteType": "Invitation",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nameOnEnvelope",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "guests",
            "storageKey": null,
            "args": null,
            "concreteType": "Guest",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "attending",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "meal",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "responseDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "anonymousGuest",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "totalAllowedGuests",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb93c9f7fabfce2a37bb1b267ca62471';
module.exports = node;
