import React from 'react'
import InvitationList from './InvitationList'
import InvitationReport from './InvitationReport'
import { Button, Col, Container, Navbar, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { QueryRenderer, graphql } from 'react-relay'
import environment from '../environment'
import type { RouterHistory } from 'react-router-dom'
import ExportToExcelButton from './ExportToExcelButton'

class Invitations extends React.Component<{
  history: RouterHistory,
}> {
  render(): React.Node {
    return (
      <Container>
        <Row>
          <Col md={3}>
        <InvitationReport />
          </Col>
          <Col md={9}>
        <QueryRenderer
          environment={environment}
          query={graphql`
            query InvitationsQuery {
              invitations {
                ...InvitationList_invitations
                ...ExportToExcelButton_invitations
              }
            }
          `}
          render={({error, props}) => {
            if (error) {
              return <div>{error.message}</div>
            } else if (props) {
              return (
                <div>
                  <Navbar className="mt-3">
                    <Button tag={Link} to="/create" color="primary">Create Invitation</Button>
                    <ExportToExcelButton invitations={props.invitations} />
                  </Navbar>
                  <InvitationList
                    className="mt-3"
                    invitations={props.invitations}
                    history={this.props.history}
                  />
                </div>
              )
            } else {
              return <div>Loading...</div>
            }
          }}
        />
        </Col>
        </Row>
      </Container>
    )
  }
}

export default Invitations
