// @flow strict
import * as React from 'react'
import { Table } from 'reactstrap'
import type { Invitation } from '../data/Invitation'
import { createFragmentContainer, graphql } from 'react-relay'
import InvitationListItem from './InvitationListItem'
import _ from 'lodash'

type Props = {
  invitations: Array<Invitation>,
}

class InvitationList extends React.Component<Props> {
  render(): React.Node {
    const { invitations } = this.props
    invitations.sort(
      (i1, i2) => {
        const r1 = _.max(_.map(i1.guests, guest => guest.responseDate))
        const r2 = _.max(_.map(i2.guests, guest => guest.responseDate))
        if (r1 === undefined) {
          return 1;
        } else if (r2 === undefined) {
          return -1;
        } else {
          return r2 < r1 ? -1 : 1;
        }
      }
    )
    return (
      <Table>
        <thead>
          <tr>
            <th>Name on Envelope</th>
            <th>Code</th>
            <th>Attending</th>
            <th>Most Recent Response</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {invitations.map(item =>
            <InvitationListItem key={item.code} item={item} />
          )}
        </tbody>
      </Table>
    )
  }
}

export default createFragmentContainer(
  InvitationList,
  graphql`
    fragment InvitationList_invitations on Invitation @relay(plural: true) {
      code
      guests {
        responseDate
      }
      ...InvitationListItem_item
    }
  `
)
