// @flow strict
import * as React from 'react'
import type { Invitation } from '../data/Invitation'
import { Badge, ListGroup, ListGroupItem } from 'reactstrap'
import { QueryRenderer, graphql } from 'react-relay'
import environment from '../environment'
import ErrorMessage from './ErrorMessage'

class InvitationReport extends React.Component<{}> {
  _renderReport(invitations: Array<Invitation>): React.Node {
    const attendance = {
      attending: 0,
      declined: 0,
      outstanding: 0,
    }
    const meals = {
      'BEEF': 0,
      'SALMON': 0,
      'VEGETARIAN': 0,
      'KIDS_MEAL': 0,
    }

    invitations.forEach(invitation => {
      invitation.guests.forEach(guest => {
        if (guest.attending === null) {
          attendance.outstanding++
        } else if (guest.attending) {
          attendance.attending++
          meals[guest.meal]++
        } else {
          attendance.declined++
        }
      })
    })

    const total =
      attendance.outstanding + attendance.declined + attendance.attending
    const percentResponded =
      Math.floor(100 * (total - attendance.outstanding) / total)

    return (
      <React.Fragment>
        <h3>{percentResponded}% Responded</h3>
        <ListGroup>
          <ListGroupItem>
            <Badge color="primary" pill>{attendance.attending}</Badge> Attending
          </ListGroupItem>
          <ListGroupItem>
            <Badge color="danger" pill>{attendance.declined}</Badge> Declined
          </ListGroupItem>
          <ListGroupItem>
            <Badge pill>{attendance.outstanding}</Badge> Outstanding
          </ListGroupItem>
        </ListGroup>
        <h3 className="mt-3">Meal Selections</h3>
        <ListGroup>
          <ListGroupItem>
            <Badge pill>{meals.BEEF}</Badge> Beef
          </ListGroupItem>
          <ListGroupItem>
            <Badge pill>{meals.SALMON}</Badge> Salmon
          </ListGroupItem>
          <ListGroupItem>
            <Badge pill>{meals.VEGETARIAN}</Badge> Vegetarian
          </ListGroupItem>
          <ListGroupItem>
            <Badge pill>{meals.KIDS_MEAL}</Badge> Kids Meal
          </ListGroupItem>
        </ListGroup>
      </React.Fragment>
    )
  }

  render(): React.Node {
    return (
      <QueryRenderer
        environment={environment}
        query={graphql`
          query InvitationReportQuery {
            invitations {
              guests {
                attending,
                meal,
              }
            }
          }
        `}
        render={({error, props}) => {
          if (error) {
            return <ErrorMessage message={error.message} />
          } else if (props) {
            return this._renderReport(props.invitations)
          } else {
            return <div>Loading...</div>
          }
        }}
      />
    )
  }
}

export default InvitationReport
