// @flow strict
import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { Link } from 'react-router-dom'
import type { Invitation } from '../data/Invitation'
import _ from 'lodash'
import moment from 'moment'

type Props = {
  item: Invitation,
}

class InvitationListItem extends React.Component<Props> {
  render() {
    const { item } = this.props
    const responseDate = _.max(_.map(item.guests, guest => guest.responseDate))
    const attending =
      _.reduce(item.guests, (acc, guest) => acc || guest.attending, false)
    const numAttending =
      _.reduce(item.guests, (acc, guest) => acc + (guest.attending ? 1 : 0), 0)
    return (
      <tr>
        <td><Link to={'/invitation/' + item.code}>{item.nameOnEnvelope}</Link></td>
        <td>{item.code}</td>
        <td>{attending === null ? '' : (attending ? 'Yes (' + numAttending + ')' : 'No')}</td>
        <td>{responseDate && moment(responseDate).fromNow()}</td>
        <td>

        </td>
      </tr>
    )
  }
}

export default createFragmentContainer(InvitationListItem,
  graphql`
    fragment InvitationListItem_item on Invitation {
      code,
      nameOnEnvelope,
      guests {
        attending,
        responseDate,
      },
    }
  `
)
