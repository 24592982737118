// @flow strict
import * as React from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Input,
  Row,
} from 'reactstrap'

type Props = {
  onLogin: void => void,
  userAttributes: any,
  user: any,
}

type State = {
  values: {
    password: string,
    confirmPassword: string,
  },
  touched: {
    password: boolean,
    confirmPassword: boolean,
  },
  errors: {
    password: ?string,
    confirmPassword: ?string,
  },
}

class ChangePassword extends React.Component<Props, State> {
  _handleChange: SyntheticInputEvent<HTMLInputElement> => void
  _validate: boolean => void
  _isValid: void => boolean
  _handleSubmit: SyntheticEvent<HTMLFormElement> => void

  constructor(props: Props) {
    super(props)
    this.state = {
      values: {
        password: '',
        confirmPassword: '',
      },
      touched: {
        password: false,
        confirmPassword: false,
      },
      errors: {
        password: null,
        confirmPassword: null,
        login: null,
      },
    }
    this._handleChange = this._handleChange.bind(this)
    this._validate = this._validate.bind(this)
    this._isValid = this._isValid.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  _handleChange(event: SyntheticInputEvent<HTMLInputElement>): void {
    const { values, touched } = this.state
    const name = event.target.name
    values[name] = event.target.value
    touched[name] = true
    this.setState({
      values,
      touched,
    })
    this._validate(false)
  }

  _validate(submit: boolean): void {
    const { values, touched, errors } = this.state
    if (submit || touched.password) {
      errors.password = values.password.trim() === '' ? 'Required' : null
    }
    if (submit || touched.confirmPassword) {
      errors.confirmPassword = values.confirmPassword.trim() === '' ? 'Required' : null
    }
    if (submit || (touched.password && touched.confirmPassword)) {
      errors.login = values.password !== values.confirmPassword ?
        'Passwords don\'t match' :
        null
    }
    this.setState({
      errors,
    })
  }

  _isValid(): boolean {
    const { errors } = this.state
    return errors.password === null &&
      errors.confirmPassword === null &&
      errors.login === null
  }

  _handleSubmit(event: SyntheticEvent<HTMLFormElement>): void {
    event.preventDefault()

    const { values, errors } = this.state
    errors.login = null
    this._validate(true)
    if (!this._isValid()) {
      return
    }

    const { onLogin, userAttributes } = this.props
    const { user } = this.props
    user.completeNewPasswordChallenge(
      values.password,
      userAttributes,
      {
        onSuccess: result => {
          onLogin(result)
        },
        onFailure: result => {
          errors.login = result.message
          this.setState({
            errors
          })
        },
      },
    )
  }

  render(): React.Node {
    const { errors } = this.state
    const isValid = this._isValid()
    return (
      <Container>
        <Row>
          <Col md={6}>
            <h1>Change Your Password</h1>
            {errors.login !== null &&
              <Alert color="danger">
                {errors.login}
              </Alert>
            }
            <Form onSubmit={this._handleSubmit}>
              <FormGroup>
                <Label for="login-password" hidden>Username</Label>
                <Input
                  type="password"
                  name="password"
                  id="login-password"
                  placeholder="Password"
                  onChange={this._handleChange}
                  invalid={errors.password !== null}
                />
                <FormFeedback>{errors.password}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="login-confirm-password" hidden>Password</Label>
                <Input
                  type="password"
                  name="confirmPassword"
                  id="login-confirm-password"
                  placeholder="Confirm Password"
                  onChange={this._handleChange}
                  invalid={errors.confirmPassword !== null}
                />
                <FormFeedback>{errors.confirmPassword}</FormFeedback>
              </FormGroup>
              <Button type="submit" color="primary" disabled={!isValid}>
                Change Password
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ChangePassword
