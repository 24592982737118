/**
 * @flow
 * @relayHash eabd630c79976a69c70d54b415b69e71
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ExportToExcelButton_invitations$ref = any;
type InvitationList_invitations$ref = any;
export type InvitationsQueryVariables = {||};
export type InvitationsQueryResponse = {|
  +invitations: $ReadOnlyArray<{|
    +$fragmentRefs: InvitationList_invitations$ref & ExportToExcelButton_invitations$ref
  |}>
|};
export type InvitationsQuery = {|
  variables: InvitationsQueryVariables,
  response: InvitationsQueryResponse,
|};
*/


/*
query InvitationsQuery {
  invitations {
    ...InvitationList_invitations
    ...ExportToExcelButton_invitations
  }
}

fragment InvitationList_invitations on Invitation {
  code
  guests {
    responseDate
  }
  ...InvitationListItem_item
}

fragment ExportToExcelButton_invitations on Invitation {
  nameOnEnvelope
  code
  guests {
    firstName
    lastName
    attending
    meal
    dietaryRestrictions
  }
}

fragment InvitationListItem_item on Invitation {
  code
  nameOnEnvelope
  guests {
    attending
    responseDate
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "kind": "Request",
  "operationKind": "query",
  "name": "InvitationsQuery",
  "id": null,
  "text": "query InvitationsQuery {\n  invitations {\n    ...InvitationList_invitations\n    ...ExportToExcelButton_invitations\n  }\n}\n\nfragment InvitationList_invitations on Invitation {\n  code\n  guests {\n    responseDate\n  }\n  ...InvitationListItem_item\n}\n\nfragment ExportToExcelButton_invitations on Invitation {\n  nameOnEnvelope\n  code\n  guests {\n    firstName\n    lastName\n    attending\n    meal\n    dietaryRestrictions\n  }\n}\n\nfragment InvitationListItem_item on Invitation {\n  code\n  nameOnEnvelope\n  guests {\n    attending\n    responseDate\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "InvitationsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invitations",
        "storageKey": null,
        "args": null,
        "concreteType": "Invitation",
        "plural": true,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "InvitationList_invitations",
            "args": null
          },
          {
            "kind": "FragmentSpread",
            "name": "ExportToExcelButton_invitations",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "InvitationsQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "invitations",
        "storageKey": null,
        "args": null,
        "concreteType": "Invitation",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "code",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "guests",
            "storageKey": null,
            "args": null,
            "concreteType": "Guest",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "responseDate",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "attending",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "meal",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dietaryRestrictions",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "nameOnEnvelope",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
// prettier-ignore
(node/*: any*/).hash = '82dc2f275eaa01c6a909a07f8cd03d78';
module.exports = node;
