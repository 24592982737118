/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type Meal = "BEEF" | "KIDS_MEAL" | "SALMON" | "VEGETARIAN" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UpdateInvitationForm_invitation$ref: FragmentReference;
export type UpdateInvitationForm_invitation = {|
  +code: string,
  +nameOnEnvelope: string,
  +guests: ?$ReadOnlyArray<?{|
    +firstName: string,
    +lastName: string,
    +attending: ?boolean,
    +meal: ?Meal,
    +responseDate: ?string,
    +anonymousGuest: ?boolean,
  |}>,
  +totalAllowedGuests: number,
  +$refType: UpdateInvitationForm_invitation$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "UpdateInvitationForm_invitation",
  "type": "Invitation",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "nameOnEnvelope",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "guests",
      "storageKey": null,
      "args": null,
      "concreteType": "Guest",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "attending",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "meal",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "responseDate",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "anonymousGuest",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalAllowedGuests",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f1eb5f4bd6d83315ec70d37438fa8812';
module.exports = node;
