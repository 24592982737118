/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type InvitationListItem_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type InvitationList_invitations$ref: FragmentReference;
export type InvitationList_invitations = $ReadOnlyArray<{|
  +code: string,
  +guests: ?$ReadOnlyArray<?{|
    +responseDate: ?string
  |}>,
  +$fragmentRefs: InvitationListItem_item$ref,
  +$refType: InvitationList_invitations$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "InvitationList_invitations",
  "type": "Invitation",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "code",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "guests",
      "storageKey": null,
      "args": null,
      "concreteType": "Guest",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "responseDate",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "InvitationListItem_item",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'a56006e6f44947e7655e1567bf3502e4';
module.exports = node;
