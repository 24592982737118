// @flow strict
import * as React from 'react'
import { Container } from 'reactstrap'
import environment from '../environment'
import type { CreateInvitationInput } from '../mutations/CreateInvitation'
import { createInvitation } from '../mutations/CreateInvitation'
import type { RouterHistory } from 'react-router-dom'
import CreateInvitationForm from './CreateInvitationForm'

type Props = {
  history: RouterHistory,
}

class CreateInvitation extends React.Component<Props> {
  onSubmit: CreateInvitationInput => void

  constructor(props: Props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(invitation: CreateInvitationInput): void {
    const { history } = this.props
    createInvitation(
      environment,
      invitation,
      (response: ?Object, errors: ?Array<Error>) => {
        if (errors !== null && errors !== undefined) {
          // TODO Display the errors.
        } else {
          history.push('/')
        }
      }
    )
  }

  render(): React.Node {
    return (
      <Container>
        <CreateInvitationForm onSubmit={this.onSubmit} />
      </Container>
    )
  }
}

export default CreateInvitation
