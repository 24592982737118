import React from 'react'
import { Alert } from 'reactstrap'

type Props = {
  message: string,
}

class ErrorMessage extends React.Component<Props> {
  render() {
    const { message } = this.props
    return (
      <Alert color="danger">
        <h4 className="alert-heading">Error!</h4>
        <hr />
        <p>{message}</p>
      </Alert>
    )
  }
}

export default ErrorMessage