// @flow strict
import * as React from 'react'
import { Container, Alert } from 'reactstrap'
import environment from '../environment'
import type { UpdateInvitationInput } from '../mutations/UpdateInvitation'
import { updateInvitation } from '../mutations/UpdateInvitation'
import type { Match, RouterHistory } from 'react-router-dom'
import UpdateInvitationForm from './UpdateInvitationForm'
import { QueryRenderer, graphql } from 'react-relay'

type Props = {
  match: Match,
  history: RouterHistory,
}

class UpdateInvitation extends React.Component<Props> {
  _onSubmit: UpdateInvitationInput => void
  _onDelete: void => void

  constructor(props: Props) {
    super(props)
    this._onSubmit = this._onSubmit.bind(this)
    this._onDelete = this._onDelete.bind(this)
  }

  _onSubmit(invitation: UpdateInvitationInput): void {
    const { code } = this.props.match.params
    const { history } = this.props
    updateInvitation(
      environment,
      code,
      invitation,
      (response: ?Object, errors: ?Array<Error>) => {
        if (errors !== null && errors !== undefined) {
          // TODO Display the errors.
        } else {
          history.push('/')
        }
      }
    )
  }

  _onDelete(): void {
    const { history } = this.props
    history.push('/')
  }

  render(): React.Node {
    const { code } = this.props.match.params
    return (
      <Container>
        <QueryRenderer
          environment={environment}
          query={graphql`
            query UpdateInvitationQuery($code: ID!) {
              invitation(code: $code) {
                ...UpdateInvitationForm_invitation
              }
            }
          `}
          variables={{
            code
          }}
          render={({error, props}) => {
            if (error) {
              return (
                <Alert color="danger" className="mt-4">
                  <h4 className="alert-heading">Error!</h4>
                  <hr />
                  <pre>{error.message}</pre>
                </Alert>
              )
            } else if (props) {
              return <UpdateInvitationForm
                onSubmit={this._onSubmit}
                onDelete={this._onDelete}
                invitation={props.invitation}
              />
            } else {
              return <div>Loading...</div>
            }
          }}
        />
      </Container>
    )
  }
}

export default UpdateInvitation
