// @flow strict
import * as React from 'react'
import _ from 'lodash'
import {
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  ButtonGroup,
  Row,
  Col,
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-solid-svg-icons'
import type { CreateInvitationInput } from '../mutations/CreateInvitation'
import { Link } from 'react-router-dom'

type Props = {
  onSubmit: CreateInvitationInput => void,
}

type GuestTouched = {
  firstName: boolean,
  lastName: boolean,
}

type InvitationTouched = {
  nameOnEnvelope: boolean,
  guests: Array<GuestTouched>,
  numAdditionalGuests: boolean,
}

type GuestErrors = {
  firstName: ?string,
  lastName: ?string,
}

type InvitationErrors = {
  nameOnEnvelope: ?string,
  guests: Array<GuestErrors>,
  numAdditionalGuests: ?string,
}

type State = {
  invitation: CreateInvitationInput,
  touched: InvitationTouched,
  errors: InvitationErrors,
}

class CreateInvitationForm extends React.Component<Props, State> {
  handleSubmit: SyntheticEvent<HTMLFormElement> => void
  handleAddGuest: number => void => void
  handleRemoveGuest: number => void => void
  handleChange: string => SyntheticInputEvent<HTMLInputElement> => void
  handleGuestChange: (number, string) => SyntheticInputEvent<HTMLInputElement> => void
  isValid: void => boolean
  validate: boolean => void

  constructor(props: Props) {
    super(props)
    this.state = {
      invitation: {
        nameOnEnvelope: '',
        guests: [
          {
            firstName: '',
            lastName: '',
            dietaryRestrictions: null,
          },
        ],
        numAdditionalGuests: 0,
      },
      touched: {
        nameOnEnvelope: false,
        guests: [
          {
            firstName: false,
            lastName: false,
          },
        ],
        numAdditionalGuests: false,
      },
      errors: {
        nameOnEnvelope: null,
        guests: [
          {
            firstName: null,
            lastName: null,
          }
        ],
        numAdditionalGuests: null,
      },
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAddGuest = this.handleAddGuest.bind(this)
    this.handleRemoveGuest = this.handleRemoveGuest.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleGuestChange = this.handleGuestChange.bind(this)
    this.validate = this.validate.bind(this)
  }

  handleSubmit(event: SyntheticEvent<HTMLFormElement>): void {
    const { onSubmit } = this.props
    event.preventDefault()
    this.validate(true)
    if (!this.isValid()) {
      // Not valid. Let this re-render.
      return
    }
    const { invitation } = this.state
    invitation.numAdditionalGuests = parseInt(invitation.numAdditionalGuests)
    if (isNaN(invitation.numAdditionalGuests)) {
      invitation.numAdditionalGuests = 0
    }
    onSubmit(invitation)
  }

  handleAddGuest(index: number) {
    return () => {
      const { invitation, touched, errors } = this.state
      invitation.guests.push({
        firstName: '',
        lastName: '',
        dietaryRestrictions: null,
      })
      touched.guests.push({
        firstName: false,
        lastName: false,
      })
      errors.guests.push({
        firstName: null,
        lastName: null,
      })
      this.setState({
        invitation,
        touched,
        errors,
      })
    }
  }

  handleRemoveGuest(index: number) {
    return () => {
      const { invitation, touched, errors } = this.state
      if (invitation.guests.length === 1) {
        invitation.guests[0].firstName = ''
        invitation.guests[0].lastName = ''
        invitation.guests[0].dietaryRestrictions = null
        touched.guests[0].firstName = false
        touched.guests[0].lastName = false
        errors.guests[0].firstName = null
        errors.guests[0].lastName = null
      } else {
        invitation.guests = _.filter(invitation.guests, (guest, i) => i !== index)
        touched.guests = _.filter(touched.guests, (guest, i) => i !== index)
        errors.guests = _.filter(errors.guests, (guest, i) => i !== index)
        this.setState({
          invitation,
          touched,
          errors,
        })
      }
    }
  }

  handleChange(name: string): SyntheticInputEvent<HTMLInputElement> => void {
    return (event: SyntheticInputEvent<HTMLInputElement>) => {
      const { invitation, touched } = this.state
      invitation[name] = event.target.value
      touched[name] = true
      this.setState({
        invitation,
        touched,
      })
      this.validate(false)
    }
  }

  handleGuestChange(index: number, name: string):
    SyntheticInputEvent<HTMLInputElement> => void {
    return (event: SyntheticInputEvent<HTMLInputElement>) => {
      const { invitation, touched } = this.state
      invitation.guests[index][name] = event.target.value
      touched.guests[index][name] = true
      this.setState({
        invitation,
        touched,
      })
      this.validate(false)
    }
  }

  isValid(): boolean {
    const { errors } = this.state
    let result = errors.nameOnEnvelope === null &&
      errors.numAdditionalGuests === null
    _.forEach(
      errors.guests,
      guest => result = result &&
        guest.firstName === null &&
        guest.lastName === null
    )
    return result
  }

  validate(submit: boolean): void {
    const { invitation, touched, errors } = this.state
    if (submit || touched.nameOnEnvelope) {
      errors.nameOnEnvelope
        = invitation.nameOnEnvelope.trim() === '' ? 'Required' : null
    }
    _.forEach(
      invitation.guests,
      (guest, i) => {
        if (submit || touched.guests[i].firstName) {
          errors.guests[i].firstName
            = guest.firstName.trim() === '' ? 'Required' : null
        }
        if (submit || touched.guests[i].lastName) {
          errors.guests[i].lastName
            = guest.lastName.trim() === '' ? 'Required' : null
        }
      }
    )
    this.setState({
      errors,
    })
  }

  render(): React.Node {
    const { invitation, errors } = this.state
    const isValid = this.isValid()
    const guests = _.map(
      invitation.guests,
      (guest, i) => (
        <React.Fragment key={'guest-' + i}>
          <Row form>
            <Col md={5}>
              <FormGroup>
                <Input
                  name={'firstName-' + i}
                  placeholder="First Name"
                  onChange={this.handleGuestChange(i, 'firstName')}
                  value={guest.firstName}
                  invalid={errors.guests[i].firstName !== null}
                />
                {errors.guests[i].firstName &&
                  <FormFeedback>{errors.guests[i].firstName}</FormFeedback>
                }
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  field={'lastName-' + i}
                  placeholder="Last Name"
                  onChange={this.handleGuestChange(i, 'lastName')}
                  value={guest.lastName}
                  invalid={errors.guests[i].lastName !== null}
                />
                {errors.guests[i].lastName &&
                  <FormFeedback>{errors.guests[i].lastName}</FormFeedback>
                }
              </FormGroup>
            </Col>
            <Col md={2}>
              <ButtonGroup size="sm">
                <Button color="link" onClick={this.handleAddGuest(i)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </Button>
                <Button color="link" onClick={this.handleRemoveGuest(i)}>
                  <FontAwesomeIcon icon={faMinusCircle} />
                </Button>
              </ButtonGroup>
              </Col>
          </Row>
        </React.Fragment>
      )
    )
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="name-on-envelope">Name on Envelope</Label>
          <Input
            id="name-on-envelope"
            name="nameOnEnvelope"
            onChange={this.handleChange('nameOnEnvelope')}
            value={invitation.nameOnEnvelope}
            invalid={errors.nameOnEnvelope !== null}
          />
          {errors.nameOnEnvelope !== null &&
            <FormFeedback>{errors.nameOnEnvelope}</FormFeedback>
          }
        </FormGroup>
        <FormGroup>
          <Label>Guests</Label>
          {guests}
        </FormGroup>
        <FormGroup>
          <Label for="num-additional-guests">Number of Additional Guests</Label>
          <Input
            id="num-additional-guests"
            name="numAdditionalGuests"
            type="number"
            min="0"
            max="10"
            onChange={this.handleChange('numAdditionalGuests')}
            value={invitation.numAdditionalGuests}
          />
        </FormGroup>
        <Button type="submit" color="primary" disabled={!isValid}>
          Create
        </Button>{' '}
        <Button tag={Link} to="/">Cancel</Button>
      </Form>
    )
  }
}

export default CreateInvitationForm
