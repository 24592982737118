/**
 * @flow
 * @relayHash 0804b25753f27c464fc26e333800d5d3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Meal = "BEEF" | "KIDS_MEAL" | "SALMON" | "VEGETARIAN" | "%future added value";
export type InvitationReportQueryVariables = {||};
export type InvitationReportQueryResponse = {|
  +invitations: $ReadOnlyArray<{|
    +guests: ?$ReadOnlyArray<?{|
      +attending: ?boolean,
      +meal: ?Meal,
    |}>
  |}>
|};
export type InvitationReportQuery = {|
  variables: InvitationReportQueryVariables,
  response: InvitationReportQueryResponse,
|};
*/


/*
query InvitationReportQuery {
  invitations {
    guests {
      attending
      meal
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "invitations",
    "storageKey": null,
    "args": null,
    "concreteType": "Invitation",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "guests",
        "storageKey": null,
        "args": null,
        "concreteType": "Guest",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "attending",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "meal",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "InvitationReportQuery",
  "id": null,
  "text": "query InvitationReportQuery {\n  invitations {\n    guests {\n      attending\n      meal\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "InvitationReportQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "InvitationReportQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be2616a695d65e085b09e2d1c73ddafc';
module.exports = node;
